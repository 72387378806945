<template>
  <div class="container">
    <img class="logo" src="@/assets/images/logo.png" />
    <div class="item">
      <div class="inputBox">
        <input
          type="text"
          v-model="UserName"
          placeholder="姓名"
        />
      </div>
    </div>
    <div class="item">
      <div class="inputBox">
        <input
          type="text"
          v-model="HospitalKeyWords"
          placeholder="请输入您所在医院关键字搜索"
        />
        <img class="searchIcon" src="@/assets/images/search_register@2x.png" />
      </div>
      <div class="HospitalList" v-show="showHospitalPicker">
        <span v-for="(item, index) in HospitalColumns" :key="index" @click="selectHospital(item.HospitalName, item.HospitalCode)">{{item.HospitalName}}</span>
      </div>
    </div>
    <div class="notFoundHospital" v-show="!notFoundHospital">
      <img
        :src="
          !notFoundHospital
            ? require('@/assets/images/radio@2x.png')
            : require('@/assets/images/dis_system_complete_icon@2x.png')
        "
      />
      <span class="declareText">未找到您所在的医院？</span>
    </div>
    <div class="item" v-show="!notFoundHospital">
      <div class="inputBox">
        <input type="text" v-model="HospitalName" placeholder="请输入您所在的医院全称" />
      </div>
    </div>
    <div class="item">
      <div class="inputBox" @click="showPositionPicker = true">
        <div class="selectInput">
          <input
            type="text"
            v-model="PositionName"
            placeholder="请选择职称"
          />
          <div></div>
        </div>
        <img class="pulldownIcon" src="@/assets/images/triangle@2x.png" />
      </div>
    </div>
    <van-popup v-model="showPositionPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="PositionColumns"
        value-key="Titles"
        @cancel="showPositionPicker = false"
        @confirm="onPositionConfirm"
      />
    </van-popup>
    <div class="item">
      <div class="inputBox" @click="showDepartmentPicker = true">
        <div class="selectInput">
          <input
            type="text"
            v-model="DepartmentName"
            placeholder="请选择科室"
          />
          <div></div>
        </div>
        <img class="pulldownIcon" src="@/assets/images/triangle@2x.png" />
      </div>
    </div>
    <van-popup v-model="showDepartmentPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="DepartmentColumns"
        value-key="Name"
        @cancel="showDepartmentPicker = false"
        @confirm="onDepartmentConfirm"
      />
    </van-popup>
    <div class="radioItem">
      <span>性别</span>
      <van-radio-group v-model="gender" direction="horizontal">
        <van-radio :name="1" checked-color="#FF6900">男</van-radio>
        <van-radio :name="0" checked-color="#FF6900">女</van-radio>
      </van-radio-group>
    </div>
    <div class="item">
      <div class="inputBox">
        <input
          type="number"
          v-model="PhoneNumber"
          placeholder="请输入手机号码"
        />
      </div>
    </div>
    <div class="item">
      <div class="codeBox">
        <input type="text" v-model="Code" maxlength="6" placeholder="请输入短信验证码" />
        <span
          class="sendCode"
          v-if="!alreadySend"
          @click="sendCode"
          :style="{ color: PhoneNumber ? '#FF6900' : '#CCCCCC' }"
          >{{isSendCode ? '重新获取' : '获取验证码'}}</span
        >
        <span class="sendCode" style="color: #FF6900" v-else>{{
          second + "S"
        }}</span>
      </div>
    </div>
    <div class="declare" @click="checkedDeclare">
      <img
        :src="
          isCheckedDeclare
            ? require('@/assets/images/system-complete.png')
            : require('@/assets/images/dis_system_complete_icon@2x.png')
        "
      />
      <span class="declareText">我已完整阅读并知晓<span class="highlight" @click="$router.push('/privacyPolicy')">《隐私声明》</span>全部内容，同意强生根据该《隐私声明》和《强生隐私政策》所述目的、范围和方式处理我提供的个人信息。</span>
    </div>
    <button class="register" @click="register">注册</button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import storage from "../../common/utils/storage";

const delay = (function() {
 let timer = 0;
 return function(callback, ms) {
  clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default {
  name: "Register",
  data() {
    return {
      UserName: '', // 用户姓名
      HospitalCode: 0, // 医院Code
      HospitalName: '', // 医院
      HospitalKeyWords: '', // 医院搜索关键字
      showHospitalPicker: false,
      HospitalColumns: [], // 医院选择项
      SelectHospital: "",
      notFoundHospital: true, // 是否找到医院
      showPositionPicker: false,
      PositionColumns: [], // 职称选择项
      PositionId: 0, // 职称ID
      PositionName: '', // 职称
      showDepartmentPicker: false,
      DepartmentColumns: [], // 科室选择项
      DepartmentCode: '', // 选中科室Code
      DepartmentName: '', // 选中科室Name
      gender: 1, // 性别
      PhoneNumber: "", // 手机号数
      Code: "", // 验证码
      isCheckedDeclare: false, // 是否同意用户协议
      second: 60,
      alreadySend: false, // 是否发送验证码
      isSendCode: false,
      isLock: true,
      validateNum: 0, // 验证验证码次数
    };
  },
  computed: {
    ...mapState(['Openid', 'userInfo']),
    sid() {
      return this.$route.query.sid;
    },
    uri() {
      return this.$route.query.uri;
    }
  },
  watch: {
    HospitalKeyWords(newValue) {
      if (newValue == this.SelectHospital) {
        this.showHospitalPicker = false;
      } else {
        delay(() => {
          this.GetHospital();
        }, 300);
      }
    }
  },
  // deactivated() {
  //   document.querySelector('meta[name="viewport"]').setAttribute('content', 'user-scalable=no');
  // },
  // activated() {
  //   document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no');
  // },
  created() {
    this.getPosition();
    this.getDepartment();

    if(!this.userInfo) {
      storage.setStore('scope', 'snsapi_userinfo');
      this.$router.push({
        path: '/authorize',
        query: {scope: 'snsapi_userinfo'}
      })
    }
  },
  methods: {
    async GetHospital() {
      let res = await this.$axios.post('/Api/Api/Web/GetWxUser/GetHospital?HospitalName=' + this.HospitalKeyWords);
      let data = res.Data;
      if (data.length > 0) {
        this.showHospitalPicker = true;
        this.HospitalColumns = data;
      } else {
        this.showHospitalPicker = false;
        this.HospitalColumns = [];
      }

      if (this.HospitalKeyWords) {
        if (data.length == 0) {
          this.notFoundHospital = false;
        } else {
          this.notFoundHospital = true;
        }
      } else {
        this.showHospitalPicker = false;
        this.notFoundHospital = true;
      }
    },
    selectHospital(HospitalName, HospitalCode) {
      this.HospitalCode = HospitalCode;
      this.HospitalKeyWords = HospitalName;
      this.SelectHospital = HospitalName;
      this.showHospitalPicker = false;
    },
    // 获取职称选项
    getPosition() {
      this.$axios.get('/Api/Api/Web/GetWxUser/GetTitle').then(res => {
        this.PositionColumns = res.Data;
      })
    },
    // 选择职称
    onPositionConfirm(value) {
      this.PositionId = value.Id;
      this.PositionName = value.Titles;
      this.showPositionPicker = false;
    },
    // 获取科室选项
    getDepartment() {
      this.$axios.get('/Api/Api/Web/GetWxUser/GetDepa').then(res => {
        this.DepartmentColumns = res.Data;
      })
    },
    // 选择科室
    onDepartmentConfirm(value) {
      this.DepartmentCode = value.Id;
      this.DepartmentName = value.Name;
      this.showDepartmentPicker = false;
    },
    // 发送验证码
    sendCode() {
      let errMsg = "";
      if (this.PhoneNumber) {
        let mobile_regex = /^(?:(?:\+|00)86)?1\d{10}$/;
        if (!mobile_regex.test(this.PhoneNumber)) {
          errMsg = "手机号码格式错误！";
        }
      } else {
        errMsg = "请输入手机号码！";
      }

      if (errMsg == "") {
        this.timer();
        this.$axios.get('/Api/Api/Web/GetWxUser/GetVCode', {
          params: {
            Phone: this.PhoneNumber,
            Type: 1
          }
        }).then((res) => {
          if (res.RetCode == '10000') {
            this.validateNum = 0;
          }
        })
      } else {
        this.$toast(errMsg);
      }
    },
    // 验证码计时
    timer() {
      this.isSendCode = true;
      this.alreadySend = true;
      let promise = new Promise((resolve) => {
        let setTimer = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.alreadySend = false;
            resolve(setTimer);
          }
        }, 1000);
      });
      promise.then((setTimer) => {
        clearInterval(setTimer);
      });
    },
    // 是否同意用户协议
    checkedDeclare() {
      this.isCheckedDeclare = !this.isCheckedDeclare;
    },
    // 注册
    register() {
      if (!this.UserName) {
        this.$toast('请输入姓名！');
        return false;
      } else {
        if (this.UserName.length < 2) {
          this.$toast('姓名不能少于2个字！');
          return false;
        }
      }


      if (this.notFoundHospital) {
        if (!this.HospitalKeyWords) {
          this.$toast('请选择所在的医院！');
          return false;
        }
      } else {
        if (!this.HospitalName) {
          this.$toast('请输入所在的医院！');
          return false;
        }
      }

      if (!this.PositionName) {
        this.$toast('请选择职称！');
        return false;
      }

      if (!this.DepartmentName) {
        this.$toast('请选择科室！');
        return false;
      }

      if (!this.PhoneNumber) {
        this.$toast('请输入手机号码！');
        return false;
      }

      if (!this.Code) {
        this.$toast('请输入短信验证码！');
        return false;
      }

      if(!this.isCheckedDeclare) {
        this.$toast('请勾选《隐私声明》并同意！');
        return false;
      }


      let data = {
        OpenID: this.Openid,
        RealName: this.UserName,
        HCode: this.notFoundHospital ? this.HospitalCode : '',
        HospitalTitle: this.notFoundHospital ? this.HospitalKeyWords : this.HospitalName,
        PositionalId: this.PositionId,
        PositionalName: this.PositionName,
        SubjectName: this.DepartmentName,
        Gender: this.gender,
        Phone: this.PhoneNumber,
        Vcode: this.Code
      }
      
      if(this.validateNum < 5) {
        if (this.isLock) {
          this.isLock = false;
          this.$axios.post('/Api/Api/Web/GetWxUser/UserRegister', data).then((res) => {
            if (res.RetCode == '10000') {
              this.isLock = true;
              storage.setStore('Token', res.Data.Token);
              let redirectUrl = sessionStorage.getItem("redirectUrl");
              if(redirectUrl) {
                this.$router.push(redirectUrl);
                sessionStorage.removeItem('redirectUrl');
              } else {
                this.$router.push('/');
              }
            } else {
              this.isLock = true;
              this.$toast(res.RetMsg);
              if(res.RetMsg == '手机验证码错误！') {
                this.validateNum++;
              }
            }
          })
        }
      } else {
        this.$toast('请重新获取验证码！');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  background: linear-gradient(to bottom, #fafafa 70%, rgba(202, 0, 27, 0.1) 100%);
  padding: 28px;
  box-sizing: border-box;
}
.logo {
  width: 150px;
  display: block;
  margin: 20px auto 40px;
}
.item {
  position: relative;
  display: flex;
  height: 55px;
  line-height: 55px;
  margin-bottom: 20px;
  border-radius: 55px;
  background-color: #F3F5F5;
  .icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ffffff;
    img {
      width: 21px;
    }
    .iphoneIcon {
      width: 16px;
    }
  }
  .HospitalList {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 9;
    width: 100%;
    max-height: 200px;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 8px 12px #ebedf0;
    overflow-y: scroll;
    span {
      padding: 5px 10px;
      display: block;
      font-size: 12px;
      line-height: 24px;
    }
    span:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }
  }
  .inputBox {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 16px;
    .selectInput {
      position: relative;
      flex: 1;
      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    input {
      flex: 1;
      padding: 0 6px;
      border: none;
      box-sizing: border-box;
      background: transparent;
      color: #333333 !important;
    }
    .searchIcon {
      width: 18px;
      height: 18px;
      padding: 0 8px;
    }
    .pulldownIcon {
      width: 16px;
      padding: 0 8px;
    }
  }
  .codeBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0 16px;
    input {
      width: 60%;
      padding: 0 6px;
      border: none;
      box-sizing: border-box;
      background: transparent;
      color: #333333 !important;
    }
    .sendCode {
      font-size: 15px;
      color: #cccccc;
    }
  }
}
.radioItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #999999;
  padding: 0 20px;
  span {
    margin-right: 10px;
  }
}
.radioItem /deep/ .van-radio__label {
  color: #999999;
}
.declare {
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  color: #666666;
  margin-top: 32px;
  img {
    width: 14px;
    height: 14px;
    margin: 2px 5px 0 10px;
  }
  .highlight {
    color: $themeColor;
  }
}
.notFoundHospital {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #333333;
  margin: 20px 0;
  text-decoration: underline;
  img {
    width: 14px;
    height: 14px;
    margin: 0 5px 0 10px;
  }
}
.register {
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  color: #ffffff;
  border: none;
  background-color: $themeColor;
  border-radius: 55px;
  margin: 28px 0 0;
}
.picker_content {
  padding-top: 10px;
  .pickerBox {
    .pickerItemBox { 
      padding: 0 8px;
      display: flex;
      flex-wrap: wrap;
      .picker_block {
        width: calc(calc(100% / 3) - 12px);
        height: 36px;
        line-height: 36px;
        text-align: center;
        border: 1px solid rgba(153, 153, 153, 0.5);
        box-sizing: border-box;
        margin: 6px;
        border-radius: 4px;
        color: #333333;
      }
      .active {
        color: #FFFFFF;
        background-color: $themeColor;
        border: none;
        box-sizing: border-box;
      }
    }
    .pickerSonItemContent {
      position: relative;
      background-color: #F8F8F8;
      padding: 8px;
      margin-top: 8px;
    }
    .pickerSonItemBox {
      // position: relative;
      display: flex;
      flex-wrap: wrap;
      background-color: #F8F8F8;
      margin: 8px 0;
      .pickerSon_block {
        width: calc(calc(100% / 3) - 12px);
        height: 36px;
        line-height: 36px;
        text-align: center;
        box-sizing: border-box;
        margin: 6px;
        border-radius: 4px;
        color: #333333;
        background-color: #FFFFFF;
      }
      .activeBg {
        color: $themeColor;
        background-image: url('../../assets/images/select_system_btn@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      .triangle {
        width: 0px;
        height: 0px;
        border-top: 8px solid #FFFFFF;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        position: absolute;
        top: 0px;
        left: 15%;
      }
    }
  }
}
.pickerSon_content {
  width: 100%;
}
.department_content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .operationBox {
    width: calc(calc(100% / 4) - 12px);
    margin: 6px;
  }
  .operationItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    background-color: #FFFFFF;
    border-radius: 4px;
    color: #333333;
    font-size: 12px;
    padding: 0 5px;
    box-sizing: border-box;
  }
}
.pickerContent {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .picker_content {
    flex: 1;
    height: 0;
    overflow-y: scroll;
  }
}
</style>
